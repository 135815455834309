<template>
  <v-card :color="cardColor" :loading="loading">
    <v-card-title> Meu perfil </v-card-title>

    <div class="pa-5">
      <v-row no-gutters>
        <v-col>
          <p>Foto de perfil atual</p>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col>
              <v-img :src="profilePhotosrc" max-width="150"></v-img>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col md="3">
              <v-file-input
                v-model="file"
                ref="file"
                show-size
                label="Adicionar arquivo:"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Nome"
            v-model="form.firstName"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Sobrenome"
            v-model="form.lastName"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Data de nascimento"
            v-model="form.date_of_birth"
            type="date"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-radio-group v-model="form.genre" row>
            <v-radio label="Masculino" :value="1"></v-radio>
            <v-radio label="Feminino" :value="2"></v-radio>
            <v-radio label="Personalizado" :value="3"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="form.genre == 3">
          <v-text-field
            label="Gênero"
            v-model="form.custom_genre"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="E-Mail"
            v-model="form.email"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Confirmar E-Mail"
            v-model="form.confirmEmail"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Alterar Senha</h3>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row>
        <v-col>
          <v-text-field
            label="Senha"
            type="password"
            required
            v-model="form.password"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Confirmar senha"
            type="password"
            required
            v-model="form.confirmPassword"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-card-actions>
      <v-row>
        <v-col>
          <v-btn class="primary darken-1" @click="save()" :loading="loading">
            <v-icon left>mdi-content-save</v-icon> Atualizar perfil
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-dialog v-model="successDialog" max-width="400">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import { secondaryColor } from "@/config/config.json";
import errorHandler from "@/helpers/error_handler";
import profileImage from "@/assets/profile.png";
import { baseUrl } from "@/config/config.json";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  directives: { mask },
  data: () => ({
    profilePhotosrc: profileImage,
    loading: false,
    successDialog: false,
    valid: true,
    file: null,
    cardColor: secondaryColor,
    form: {},
  }),
  computed: {
    ...mapState("user", ["userData"]),
  },
  methods: {
    ...mapMutations("user", ["setUser"]),
    ...mapActions("user", ["loadProfilePhoto"]),
    validateForm() {
      const errors = [];

      if (!this.form.firstName || this.form.firstName.length < 3)
        errors.push("O campo NOME é obrigatório");

      if (!this.form.lastName || this.form.lastName.length < 3)
        errors.push("O campo SOBRENOME é obrigatório");

      if (this.form.password && this.form.password != this.form.confirmPassword)
        errors.push("Os campos SENHA e CONFIRMAR SENHA não conferem");

      if (!this.form.email) errors.push("O campo E-MAIL é obrigatório");

      if (this.form.confirmEmail && this.form.email != this.form.confirmEmail)
        errors.push("Os campos E-MAIL e CONFIRMAR E-MAIL não conferem");

      if (!this.form.genre) errors.push("O campo GÊNERO é obrigatório");
      else if (this.form.genre == 3 && !this.form.custom_genre)
        errors.push("Informe o gênero PERSONALIZADO");

      if (!this.form.date_of_birth)
        errors.push("O campo DATA DE NASCIMENTO é obrigatório");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this.updateProfile();
    },
    async updateProfile() {
      try {
        this.loading = true;

        let formData = new FormData();

        formData.append("file", this.file);

        let dataToSend = { ...this.form };

        for (const key of Object.keys(dataToSend)) {
          if (dataToSend[key]) formData.append(key, dataToSend[key]);
        }

        await this.$axios.post(`/usuarios/perfil/editar`, formData);

        this.setUser({ ...this.form });

        if (this.file) this.loadProfilePhoto();

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    closeSuccessDialog() {
      this.successDialog = false;

      this.initializeForm();
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/usuarios/perfil`);

        this.form = { ...res.data };

        if (this.form.profile_photo) {
          this.profilePhotosrc = `${baseUrl}/uploads/usersavatar/${this.form.profile_photo}`;
          this.setUser({ ...this.form });
        }
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  mounted() {
    this.initializeForm();
  },
};
</script>

<style>
.required label::after {
  content: " *";
  color: red;
}
</style>
